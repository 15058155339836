/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import { createAction } from "redux-actions";

export const GET_ALL_TEMPLATES = createAction("GET_ALL_TEMPLATES");
export const GET_ALL_TEMPLATES_SUCCESS = createAction(
  "GET_ALL_TEMPLATES_SUCCESS"
);
export const GET_ALL_TEMPLATES_FAILED = createAction(
  "GET_ALL_TEMPLATES_FAILED"
);

export const GET_INFO_TEMPLATE = createAction("GET_INFO_TEMPLATE");
export const GET_INFO_TEMPLATE_SUCCESS = createAction(
  "GET_INFO_TEMPLATE_SUCCESS"
);
export const GET_INFO_TEMPLATE_FAILED = createAction(
  "GET_INFO_TEMPLATE_FAILED"
);

export const GET_ALL_FAST_ITEM = createAction("GET_ALL_FAST_ITEM");
export const GET_ALL_FAST_ITEM_SUCCESS = createAction(
  "GET_ALL_FAST_ITEM_SUCCESS"
);
export const GET_ALL_FAST_ITEM_FAILED = createAction(
  "GET_ALL_FAST_ITEM_FAILED"
);
export const GET_ALL_PATIENT_INFO = createAction("GET_ALL_PATIENT_INFO");
export const GET_ALL_PATIENT_INFO_SUCCESS = createAction(
  "GET_ALL_PATIENT_INFO_SUCCESS"
);
export const GET_ALL_PATIENT_INFO_FAILED = createAction(
  "GET_ALL_PATIENT_INFO_FAILED"
);

export const GET_ALL_MEDICALS = createAction("GET_ALL_MEDICALS");
export const GET_ALL_MEDICALS_SUCCESS = createAction(
  "GET_ALL_MEDICALS_SUCCESS"
);
export const GET_ALL_MEDICALS_FAILED = createAction("GET_ALL_MEDICALS_FAILED");

export const CHANGE_INFO_TEMPLATE = createAction("CHANGE_INFO_TEMPLATE");
export const CHANGE_INFO_TEMPLATE_SUCCESS = createAction(
  "CHANGE_INFO_TEMPLATE_SUCCESS"
);
export const CHANGE_INFO_TEMPLATE_FAILED = createAction(
  "CHANGE_INFO_TEMPLATE_FAILED"
);

export const CLEAR = createAction("CLEAR_TEMPLATES");
