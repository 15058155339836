/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import routes, { renderRoutes } from "./routes";
import { ToastProvider } from "react-toast-notifications";

const App = () => {
  return (
    <React.Fragment>
      <ToastProvider>
        <Router>{renderRoutes(routes)}</Router>
      </ToastProvider>
    </React.Fragment>
  );
};

export default App;
