/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// import Loader from "./components/Loader/Loader";
import AdminLayout from "./layouts/AdminLayout";
import AuthGuard from "./components/Auth/AuthGuard";
import Authenticate from "./screens/Authentication/router";
import Profile from "./screens/Profile/router";
import ManageUser, { ROUTER_MANAGE_USER } from "./screens/ManageUser/router";
import ManageItems from "./screens/ManageItems/router";
import ManageEmployeeIntegration from "./screens/Integration/router";
import ManagePatient from "./screens/ManagePatient/router";
import ManageSchedule from "./screens/ManageSchedulers/router";
import ManageTemplate from "./screens/ManageTemplate/router";
import ManageMedical from "./screens/ManageMedical/router";
import ManageOrder from "./screens/ManageOrders/router";
import ManagePurchases from "./screens/ManagePurchases/router";
import Dashboard from "./screens/Dashboard/router";

export const renderRoutes = (routes = []) => (
  <Suspense fallback="Đang tải ...">
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
      <Redirect to={ROUTER_MANAGE_USER} />
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("./views/errors/NotFound404")),
  },
  {
    exact: true,
    path: "/maintenance/coming-soon",
    component: lazy(() => import("./views/maintenance/ComingSoon")),
  },
  {
    exact: true,
    path: "/maintenance/error",
    component: lazy(() => import("./views/maintenance/Error")),
  },
  ...Authenticate,
  {
    path: "*",
    layout: AdminLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: "/app/dashboard/default",
        component: lazy(() => import("./views/dashboard/DashDefault")),
      },
      {
        exact: true,
        path: "/app/dashboard/e-commerce",
        component: lazy(() => import("./views/dashboard/DashEcommerce")),
      },
      {
        exact: true,
        path: "/app/dashboard/crm",
        component: lazy(() => import("./views/dashboard/DashCrm")),
      },
      {
        exact: true,
        path: "/app/dashboard/analytics",
        component: lazy(() => import("./views/dashboard/DashAnalytics")),
      },
      {
        exact: true,
        path: "/app/dashboard/crypto",
        component: lazy(() => import("./views/dashboard/DashCrypto")),
      },
      {
        exact: true,
        path: "/app/dashboard/project",
        component: lazy(() => import("./views/dashboard/DashProject")),
      },
      {
        exact: true,
        path: "/charts/amchart",
        component: lazy(() => import("./views/charts/am-chart")),
      },
      {
        exact: true,
        path: "/charts/apex-chart",
        component: lazy(() => import("./views/charts/apex-chart")),
      },
      {
        exact: true,
        path: "/charts/chart-js",
        component: lazy(() => import("./views/charts/chart-js")),
      },
      {
        exact: true,
        path: "/charts/e-chart",
        component: lazy(() => import("./views/charts/e-chart")),
      },
      {
        exact: true,
        path: "/charts/google-chart",
        component: lazy(() => import("./views/charts/google-chart")),
      },
      {
        exact: true,
        path: "/charts/highchart",
        component: lazy(() => import("./views/charts/highchart")),
      },
      {
        exact: true,
        path: "/charts/rechart",
        component: lazy(() => import("./views/charts/re-chart")),
      },
      {
        exact: true,
        path: "/charts/nvd3",
        component: lazy(() => import("./views/charts/nvd3-chart")),
      },
      {
        exact: true,
        path: "/charts/radial",
        component: lazy(() => import("./views/charts/radial-chart")),
      },
      ...ManageUser,
      ...ManageEmployeeIntegration,
      ...Profile,
      ...ManageItems,
      ...ManagePatient,
      ...ManageSchedule,
      ...ManageMedical,
      ...ManageOrder,
      // ...ManageTemplate,
      ...Dashboard,
      ...ManagePurchases,
    ],
  },
];

export default routes;
