/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import { all } from "redux-saga/effects";
import { sagas as Authen } from "../screens/Authentication";
// import { sagas as Employees } from '../screens/employees';

export default function* rootSaga() {
    yield all([Authen()]);
}
