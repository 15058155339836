/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import { createAction } from "redux-actions";

export const GET_ALL_SCHEDULES = createAction("SCHEDULE/GET_ALL_SCHEDULES");
export const GET_ALL_SCHEDULES_SUCCESS = createAction(
  "SCHEDULE/GET_ALL_SCHEDULES_SUCCESS"
);
export const GET_ALL_SCHEDULES_FAILED = createAction(
  "SCHEDULE/GET_ALL_SCHEDULES_FAILED"
);
export const GET_ALL_SCHEDULES_LIST = createAction(
  "SCHEDULE/SCHEDULE/GET_ALL_SCHEDULES_LIST"
);
export const GET_ALL_SCHEDULES_LIST_SUCCESS = createAction(
  "SCHEDULE/GET_ALL_SCHEDULES_LIST_SUCCESS"
);
export const GET_ALL_SCHEDULES_LIST_FAILED = createAction(
  "SCHEDULE/GET_ALL_SCHEDULES_LIST_FAILED"
);
export const GET_INFO_SCHEDULE = createAction("SCHEDULE/GET_INFO_SCHEDULE");
export const GET_INFO_SCHEDULE_SUCCESS = createAction(
  "SCHEDULE/GET_INFO_SCHEDULE_SUCCESS"
);
export const GET_INFO_SCHEDULE_FAILED = createAction(
  "SCHEDULE/GET_INFO_SCHEDULE_FAILED"
);

export const CHANGE_INFO_SCHEDULE = createAction(
  "SCHEDULE/SCHEDULE/CHANGE_INFO_SCHEDULE"
);
export const CHANGE_INFO_SCHEDULE_SUCCESS = createAction(
  "SCHEDULE/CHANGE_INFO_SCHEDULE_SUCCESS"
);
export const CHANGE_INFO_SCHEDULE_FAILED = createAction(
  "SCHEDULE/CHANGE_INFO_SCHEDULE_FAILED"
);

export const GET_ALL_PATIENT_INFO = createAction(
  "SCHEDULE/SCHEDULE/GET_ALL_PATIENT_INFO"
);
export const GET_ALL_PATIENT_INFO_SUCCESS = createAction(
  "SCHEDULE/GET_ALL_PATIENT_INFO_SUCCESS"
);
export const GET_ALL_PATIENT_INFO_FAILED = createAction(
  "SCHEDULE/GET_ALL_PATIENT_INFO_FAILED"
);

export const ADD_PATIENT_INFO = createAction("SCHEDULE/ADD_PATIENT_INFO");
export const ADD_PATIENT_INFO_SUCCESS = createAction(
  "SCHEDULE/ADD_PATIENT_INFO_SUCCESS"
);
export const ADD_PATIENT_INFO_FAILED = createAction(
  "SCHEDULE/SCHEDULE/ADD_PATIENT_INFO_FAILED"
);

export const CLEAR = createAction("SCHEDULE/CLEAR_SCHEDULES");
