/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import * as actions from "./actions";
import freeze from "deep-freeze";
import { handleActions } from "redux-actions";
import moment from "moment";
import _ from "lodash";

export const initialState = freeze({
  medicals: [],
  item: {},
  isLoading: false,
  listPatients: [],
  patientOptions: [],
  patient: {},
  patientLogs: [],
  patientMedicals: [],
  medicalProfile: null,
});

export const name = "MEDICALS";

export default handleActions(
  {
    [actions.GET_ALL_MEDICALS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true,
      });
    },
    [actions.GET_ALL_MEDICALS_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        medicals: action.payload?.find_all_medical?.data.map((item) => {
          return {
            ...item,
            startDate: item?.startDate
              ? moment(item?.startDate).format("DD/MM/YYYY")
              : "",
            endDate: item?.endDate
              ? moment(item?.endDate).format("DD/MM/YYYY")
              : "",
          };
        }),
        page: action.payload.find_all_medical?.skip,
        pageSize: action.payload.find_all_medical?.take,
        totalPage: Math.ceil(
          action.payload.find_all_medical?.total /
            action.payload.find_all_medical?.take
        ),
        isNextPage: action.payload.find_all_medical?.nextPage,
      });
    },
    [actions.GET_ALL_MEDICALS_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },
    [actions.GET_MEDICAL_PROFILE]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true,
      });
    },
    [actions.GET_MEDICAL_PROFILE_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        medicalProfile: {
          ...action.payload,
          createdat: moment(action.payload?.createdat).format("YYYY-MM-DD"),
          updatedat: moment(action.payload?.updatedat).format("DD/MM/YYYY"),
          updatedby: `${action.payload?.updatedby?.lastname} ${action.payload?.updatedby?.firstname}`,
          dateExaminate: action.payload?.dateExaminate
            ? moment(action.payload?.dateExaminate).format("YYYY-MM-DD")
            : "",
        },
      });
    },
    [actions.GET_MEDICAL_PROFILE_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },
    [actions.GET_MEDICAL_PROFILE]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true,
      });
    },
    [actions.CHANGE_INFO_MEDICAL_PROFILE_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        item: {
          ...action.payload.save_item,
          startDate: moment(action.payload?.save_item.startDate).format(
            "YYYY-MM-DD"
          ),
          endDate: moment(action.payload?.save_item.endDate).format(
            "YYYY-MM-DD"
          ),
        },
      });
    },
    [actions.CHANGE_INFO_MEDICAL_PROFILE_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },
    [actions.GET_ALL_PATIENT_INFO_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        patientOptions: action.payload?.find_fast_patient?.map((item) => {
          return {
            label: `${item?.lastname} ${item?.firstname} - Mã BN: ${item?.id} ${
              item?.dob ? `- ${moment(item?.dob).format("DD/MM/YYYY")}` : ""
            }`,
            value: item?.id,
          };
        }),
        listPatients: action.payload?.find_fast_patient?.map((item) => ({
          ...item,
          dob: item?.dob && moment(item?.dob).format("YYYY-MM-DD"),
          identityDate: item?.identityDate
            ? moment(item?.identityDate).format("YYYY-MM-DD")
            : "",
        })),
      });
    },
    [actions.GET_PATIENT_INFO_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        patient: {
          ...action.payload,
          phone:
            action.payload?.phone?.replace(" ", "")?.replace("(+84)", "") || "",
          dob: moment(action.payload?.dob).format("YYYY-MM-DD"),
        },
        patientMedicals: action.payload?.medical,
      });
    },
    [actions.CLEAR]: (state, action) => {
      return freeze(initialState);
    },
    [actions.ADD_MEDICAL_RESULT_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        medicalProfile: {
          ...state.medicalProfile,
          results: [
            ...state.medicalProfile?.results,
            action.payload?.save_medical_result,
          ],
        },
      });
    },
    [actions.GET_PATIENT_LOG_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        patientLogs: action.payload?.find_all_logs?.data.map((item) => {
          return {
            ...item,
            startDate: item?.startDate
              ? moment(item?.startDate).format("DD/MM/YYYY")
              : "",
            endDate: item?.endDate
              ? moment(item?.endDate).format("DD/MM/YYYY")
              : "",
          };
        }),
        page: action.payload.find_all_logs?.skip,
        pageSize: action.payload.find_all_logs?.take,
        totalPage: Math.ceil(
          action.payload.find_all_logs?.total /
            action.payload.find_all_logs?.take
        ),
        isNextPage: action.payload.find_all_logs?.nextPage,
      });
    },
  },
  initialState
);
