/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import { createAction } from "redux-actions";

export const GET_ALL_MEDICALS = createAction("MEDICAL/GET_ALL_MEDICALS");
export const GET_ALL_MEDICALS_SUCCESS = createAction(
  "MEDICAL/GET_ALL_MEDICALS_SUCCESS"
);
export const GET_ALL_MEDICALS_FAILED = createAction(
  "MEDICAL/GET_ALL_MEDICALS_FAILED"
);

export const GET_MEDICAL_PROFILE = createAction("MEDICAL/GET_MEDICAL_PROFILE");
export const GET_MEDICAL_PROFILE_SUCCESS = createAction(
  "MEDICAL/GET_MEDICAL_PROFILE_SUCCESS"
);
export const GET_MEDICAL_PROFILE_FAILED = createAction(
  "MEDICAL/GET_MEDICAL_PROFILE_FAILED"
);

export const CHANGE_INFO_MEDICAL_PROFILE = createAction(
  "MEDICAL/CHANGE_INFO_MEDICAL_PROFILE"
);
export const CHANGE_INFO_MEDICAL_PROFILE_SUCCESS = createAction(
  "MEDICAL/CHANGE_INFO_MEDICAL_PROFILE_SUCCESS"
);
export const CHANGE_INFO_MEDICAL_PROFILE_FAILED = createAction(
  "MEDICAL/CHANGE_INFO_MEDICAL_PROFILE_FAILED"
);
export const GET_ALL_PATIENT_INFO_SUCCESS = createAction(
  "MEDICAL/GET_ALL_PATIENT_INFO_SUCCESS"
);
export const GET_PATIENT_INFO_SUCCESS = createAction(
  "MEDICAL/GET_PATIENT_INFO_SUCCESS"
);
export const CLEAR = createAction("MEDICAL/CLEAR_ITEMS");

export const GET_PATIENT_LOG_SUCCESS = createAction(
  "MEDICAL/GET_PATIENT_LOG_SUCCESS"
);
export const ADD_MEDICAL_RESULT_SUCCESS = createAction(
  "MEDICAL/ADD_MEDICAL_RESULT_SUCCESS"
);
