/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import { createAction } from "redux-actions";

export const GET_EMPLOYEE_INFO = createAction("USER/GET_EMPLOYEE_INFO");
export const GET_EMPLOYEE_INFO_SUCCESS = createAction(
  "USER/GET_EMPLOYEE_INFO_SUCCESS"
);
export const GET_EMPLOYEE_INFO_FAILED = createAction(
  "USER/GET_EMPLOYEE_INFO_FAILED"
);

export const GET_ALL_EMPLOYEE_INFO = createAction("USER/GET_ALL_EMPLOYEE_INFO");
export const GET_ALL_EMPLOYEE_INFO_SUCCESS = createAction(
  "USER/GET_ALL_EMPLOYEE_INFO_SUCCESS"
);
export const GET_ALL_EMPLOYEE_INFO_FAILED = createAction(
  "USER/GET_ALL_EMPLOYEE_INFO_FAILED"
);

export const CLEAR = createAction("USER/CLEAR_EMPLOYEE");

export const DELETE_EMPLOYEE = createAction("USER/DELETE_EMPLOYEE");
export const DELETE_EMPLOYEE_SUCCESS = createAction(
  "USER/DELETE_EMPLOYEE_SUCCESS"
);
export const DELETE_EMPLOYEE_FAILED = createAction(
  "USER/DELETE_EMPLOYEE_FAILED"
);
