/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import * as actions from "./actions";
import freeze from "deep-freeze";
import { handleActions } from "redux-actions";
import moment from "moment";

export const initialState = freeze({
  isLoggedIn: false,
  isInitialised: false,
  user: null,
  dataSignUp: {
    username: "",
    password: "",
  },
  isLoading: false,
});

export const name = "AUTHENTICATION";

export default handleActions(
  {
    [actions.ACCOUNT_INITIALISE]: (state, action) => {
      const { isLoggedIn, user } = action.payload;
      return freeze({
        ...state,
        isLoggedIn,
        isInitialised: true,
        user,
      });
    },
    [actions.LOGIN]: (state, action) => {
      const { user } = action.payload;
      return freeze({
        ...state,
        isLoggedIn: true,
        isInitialised: true,
        user,
        dataSignUp: action.payload,
      });
    },
    [actions.LOGIN_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoggedIn: true,
        user: {
          ...action.payload,
          dob: moment(action.payload).format("YYYY-MM-DD"),
          decentralization: action.payload.decentralization?.map((item) => {
            return {
              ...item,
              integration:
                item?.employees > 0 || item?.items > 0 || item?.patients > 0
                  ? 1
                  : 0,
            };
          }),
        },
      });
    },
    [actions.LOGIN_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoggedIn: false,
        user: initialState.user,
      });
    },
    [actions.LOGOUT]: (state, action) => {
      return freeze({
        ...state,
        isLoggedIn: false,
        isInitialised: true,
        user: null,
      });
    },
    [actions.CHANGE_PASSWORD]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true,
      });
    },
    [actions.CHANGE_PASSWORD_SUCCESS]: (state, action) => {
      console.log({ action });
      return freeze({
        ...state,
        isLoading: false,
      });
    },
    [actions.CHANGE_PASSWORD_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },
  },
  initialState
);
