/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import { combineReducers } from "@reduxjs/toolkit";
import { reducer as formReducer } from "redux-form";
import Authen, { name as nameOfAuthen } from "../screens/Authentication";
import User, { name as nameOfUser } from "../screens/ManageUser";
import Item, { name as nameOfItem } from "../screens/ManageItems";
import Profile, { name as nameOfProfile } from "../screens/Profile";
import Integration, { name as nameOfIntegration } from "../screens/Integration";
import Patient, { name as nameOfPatient } from "../screens/ManagePatient";
import Orders, { name as nameOfOrders } from "../screens/ManageOrders";
import Purchases, { name as nameOfPurchases } from "../screens/ManagePurchases";
import Templates, { name as nameOfTemplate } from "../screens/ManageTemplate";
import Medical, { name as nameOfMedical } from "../screens/ManageMedical";
import Schedules, {
  name as nameOfSchedules,
} from "../screens/ManageSchedulers";

const reducers = combineReducers({
  form: formReducer,
  [nameOfAuthen]: Authen,
  [nameOfUser]: User,
  [nameOfItem]: Item,
  [nameOfProfile]: Profile,
  [nameOfIntegration]: Integration,
  [nameOfPatient]: Patient,
  [nameOfSchedules]: Schedules,
  [nameOfOrders]: Orders,
  [nameOfPurchases]: Purchases,
  [nameOfTemplate]: Templates,
  [nameOfMedical]: Medical,
});

export default reducers;
