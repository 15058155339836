import React, { useContext, useState } from "react";
import { ListGroup, Dropdown, Media } from "react-bootstrap";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";

import ChatList from "./ChatList";
import { ConfigContext } from "../../../../contexts/ConfigContext";
import useAuth from "../../../../hooks/useAuth";

import avatar1 from "../../../../assets/images/user/avatar-1.jpg";
import avatar2 from "../../../../assets/images/user/avatar-2.jpg";
import avatar3 from "../../../../assets/images/user/avatar-3.jpg";
import avatar4 from "../../../../assets/images/user/avatar-4.jpg";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const NavRight = () => {
  const configContext = useContext(ConfigContext);
  const { rtlLayout } = configContext.state;

  const [listOpen, setListOpen] = useState(false);

  const { user } = useSelector((state) => state["AUTHENTICATION"]);

  const logout = () => {
    Cookies.remove("accesstoken");
    Cookies.remove("refreshtoken");
  };

  const handleLogout = async () => {
    try {
      //handleClose();
      await logout();
      global.location.reload();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <React.Fragment>
      <ListGroup
        as="ul"
        bsPrefix=" "
        className="navbar-nav ml-auto"
        id="navbar-right"
        style={{ zIndex: 0 }}
      >
        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown alignRight={!rtlLayout} className="drp-user">
            <Dropdown.Toggle
              as={Link}
              variant="link"
              to="#"
              id="dropdown-basic"
            >
              <i className="icon feather icon-settings" />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="profile-notification">
              <div className="pro-head">
                <img src={avatar1} className="img-radius" alt="User Profile" />
                <span>
                  <b>
                    {user?.lastname} {user?.firstname}
                  </b>
                </span>
              </div>
              <ListGroup
                as="ul"
                bsPrefix=" "
                variant="flush"
                className="pro-body"
              >
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="/profile" className="dropdown-item">
                    <i className="feather icon-user" />
                    Thông tin cá nhân
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="/auth/change-password" className="dropdown-item">
                    <i className="feather icon-lock" /> Đổi mật khẩu
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item" onClick={handleLogout}>
                    <i className="feather icon-log-out" /> Đăng xuất
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
      </ListGroup>
      <ChatList listOpen={listOpen} closed={() => setListOpen(false)} />
    </React.Fragment>
  );
};

export default NavRight;
