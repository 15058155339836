/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import * as actions from "./actions";
import freeze from "deep-freeze";
import { handleActions } from "redux-actions";
import * as utils from "./utils";

export const initialState = freeze({
    convertFileEmployee: {
        TÊN: { value: "firstname", label: "Tên" },
        "HỌ VÀ TÊN LÓT": { value: "lastname", label: "Họ và Tên lót" },
        EMAIL: { value: "email", label: "Email" },
        "NGÀY SINH": { value: "dob", label: "Ngày sinh" },
        "ĐIỆN THOẠI": { value: "phone", label: "Số điện thoại" },
        "GIỚI TÍNH": { value: "gender", label: "Giới tính" },
        "ĐỊA CHỈ": { value: "address", label: "Địa chỉ" },
        "TỈNH/THÀNH PHỐ": { value: "province", label: "Tỉnh/Thành phố" },
        "QUẬN/HUYỆN/THÀNH PHỐ": { value: "city", label: "Quận/Huyện/Thành phố" },
        "QUỐC TỊCH": { value: "nation", label: "Quốc tịch" },
        "CMND/CCCD": { value: "cardID", label: "CMND/CCCD" },
        "GHI CHÚ": { value: "note", label: "Ghi chú" },
        "TRẠNG THÁI": { value: "status", label: "Trạng thái" },
        "TÀI KHOẢN": { value: "username", label: "Tên tài khoản" },
        "MẬT KHẨU": { value: "password", label: "Mật khẩu" },
    },
    convertFileItem: {
        TÊN: { value: "name", label: "Tên" },
        "VIẾT TẮT": { value: "shortName", label: "Viết Tắt" },
        "PHÂN LOẠI": { value: "category", label: "Phân Loại" },
        SKU: { value: "sku", label: "SKU" },
        SERI: { value: "seri", label: "SERI" },
        UPC: { value: "upc", label: "UPC" },
        KHO: { value: "bin", label: "Kho" },
        "GIÁ BÁN": { value: "salePrices", label: "Giá Bán" },
        "GIÁ NHẬP": { value: "purchasePrices", label: "Giá Nhập" },
        "GHI CHÚ": { value: "note", label: "Ghi chú" },
        "NGÀY SẢN XUẤT": { value: "startDate", label: "Ngày sản xuất" },
        "NGÀY HẾT HẠN": { value: "endDate", label: "Ngày hết hạn" },
    },
    convertFilePatient: {
        TÊN: { value: "firstname", label: "Tên" },
        "HỌ VÀ TÊN LÓT": { value: "lastname", label: "Họ và Tên lót" },
        EMAIL: { value: "email", label: "Email" },
        "NGÀY SINH": { value: "dob", label: "Ngày sinh" },
        "ĐIỆN THOẠI": { value: "phone", label: "Số điện thoại" },
        "GIỚI TÍNH": { value: "gender", label: "Giới tính" },
        "ĐỊA CHỈ": { value: "address", label: "Địa chỉ" },
        "TỈNH/THÀNH PHỐ": { value: "province", label: "Tỉnh/Thành phố" },
        "QUẬN/HUYỆN/THÀNH PHỐ": { value: "city", label: "Quận/Huyện/Thành phố" },
        "QUỐC TỊCH": { value: "nation", label: "Quốc tịch" },
        "CMND/CCCD": { value: "identityNumber", label: "CMND/CCCD" },
        "NGÀY CẤP": { value: "identityDate", label: "Ngày cấp" },
        "NƠI CẤP": { value: "identityLocation", label: "Nơi cấp" },
    },

    //refactor
    employees: [],
    isChangeTBLEmployee: false,
    paginationEmployee: { take: 5, skip: 0 },
    dataEmployee: null,
    isEnabledEmployee: true,
    messageEmployee: "",
    selectedRows: [],
    selectedOne: null,
    //preview
    listItems: [],
    listColumn: [],
    selectedRowsPreview: [],
    selectedOnePreview: null,
});

export const name = "INTEGRATION";

export default handleActions(
    {
        //other
        [actions.CLEAR]: (state, action) => {
            return freeze({ ...initialState, isChangeTBLEmployee: true });
        },
        // table
        [actions.FIND_ALL_DATA_SUCCESS]: (state, action) => {
            return freeze({
                ...state,
                isEnabledEmployee: false,
                employees: action.payload.data,
                isChangeTBLEmployee: false,
                paginationEmployee: utils.handlePagination(action.payload.pagination),
            });
        },
        [actions.FIND_ALL_DATA_FAILED]: (state, action) => {
            return freeze({
                ...state,
                isEnabledEmployee: true,
                isChangeTBLEmployee: false,
                messageEmployee: action.payload,
                paginationEmployee: initialState.paginationEmployee,
            });
        },
        [actions.HANDLE_DROP_BTN_TABLE]: (state, action) => {
            return freeze({
                ...state,
                employees: state.employees.map((o) =>
                    o.id === action.payload.index ? { ...o, button: action.payload.value } : o
                ),
            });
        },
        [actions.HANDLE_PAGINATION_TABLE]: (state, action) => {
            return freeze({
                ...state,
                isChangeTBLEmployee: true,
                paginationEmployee: {
                    ...state.paginationEmployee,
                    ...action.payload,
                },
            });
        },
        [actions.SELECT_ONE]: (state, action) => {
            return freeze({
                ...state,
                selectedOne: action.payload,
            });
        },
        [actions.SELECT_ONE_SUCCESS]: (state, action) => {
            return freeze({
                ...state,
                selectedOne: initialState.selectedOne,
            });
        },
        [actions.SELECT_ROW]: (state, action) => {
            return freeze({
                ...state,
                selectedRows: action.payload,
            });
        },
        [actions.SELECT_ROW_SUCCESS]: (state, action) => {
            return freeze({
                ...state,
                selectedRows: initialState.selectedRows,
            });
        },
        /// PREVIEW TABLE
        [actions.HANDLE_DROP_BTN_TABLE_PREVIEW]: (state, action) => {
            return freeze({
                ...state,
                listItems: state.listItems.map((o) =>
                    o.index === action.payload.index ? { ...o, button: action.payload.value } : o
                ),
            });
        },
        [actions.IMPORT_DATA]: (state, action) => {
            return freeze({
                ...state,
                listItems: action.payload.rows,
                listColumn: action.payload.columns,
                fileName: action.payload.fileName,
            });
        },
        [actions.DELETE_ROW_TABLE_PREVIEW]: (state, action) => {
            return freeze({
                ...state,
                listItems: state.listItems.filter((item) => item.index !== action.payload),
            });
        },
        [actions.SELECT_ONE_TABLE_REVIEW]: (state, action) => {
            return freeze({
                ...state,
                selectedOnePreview: action.payload,
            });
        },
        [actions.SELECT_ONE_TABLE_REVIEW_SUCCESS]: (state, action) => {
            return freeze({
                ...state,
                selectedOnePreview: initialState.selectedOnePreview,
            });
        },
        [actions.SELECT_ROW_TABLE_REVIEW]: (state, action) => {
            return freeze({
                ...state,
                selectedRowsPreview: action.payload,
            });
        },
        [actions.SELECT_ROW_TABLE_REVIEW_SUCCESS]: (state, action) => {
            return freeze({
                ...state,
                selectedRowsPreview: initialState.selectedRowsPreview,
            });
        },
        [actions.HANDLE_INPUT_TABLE_REVIEW]: (state, action) => {
            const { index, id, value } = action.payload;
            return freeze({
                ...state,
                listItems: state.listItems.map((o) => (o.index === index ? { ...o, [id]: value } : o)),
            });
        },
    },
    initialState
);
