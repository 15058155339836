/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import * as actions from "./actions";
import freeze from "deep-freeze";
import { handleActions } from "redux-actions";
import moment from "moment";
import { calcDataItem, calcDataServices, DEFAULT_VALUE_ITEM, DEFAULT_VALUE_SERVICE } from "./utils";

export const initialState = freeze({
    orders: [],
    order: {},
    isLoading: false,
    items: [],
    orderItems: [DEFAULT_VALUE_ITEM],
    orderServices: [DEFAULT_VALUE_SERVICE],
});

export const name = "ORDERS";

export default handleActions(
    {
        [actions.GET_ALL_ORDERS]: (state, action) => {
            return freeze({
                ...state,
                isLoading: true,
            });
        },
        [actions.GET_ALL_ORDERS_SUCCESS]: (state, action) => {
            return freeze({
                ...state,
                isLoading: false,
                orders: action.payload.find_all_sales_orders?.data.map((item) => {
                    return {
                        ...item,
                        startDate: item?.startDate ? moment(item?.startDate).format("DD/MM/YYYY") : "",
                        endDate: item?.endDate ? moment(item?.endDate).format("DD/MM/YYYY") : "",
                    };
                }),
                page: action.payload.find_all_sales_orders?.skip,
                pageSize: action.payload.find_all_sales_orders?.take,
                totalPage: Math.ceil(
                    action.payload.find_all_sales_orders?.total / action.payload.find_all_sales_orders?.take
                ),
                isNextPage: action.payload.find_all_sales_orders?.nextPage,
            });
        },
        [actions.GET_ALL_ORDERS_FAILED]: (state, action) => {
            return freeze({
                ...state,
                isLoading: false,
            });
        },
        [actions.GET_ALL_FAST_ITEM_SUCCESS]: (state, action) => {
            return freeze({
                ...state,
                items: action.payload.map((item) => ({
                    ...item,
                    value: item.id,
                    label: item.shortName ? item.shortName : item.name,
                    basePrice: parseInt(item.salePrices),
                    price: parseInt(item.salePrices),
                })),
            });
        },
        [actions.GET_ALL_PATIENT_INFO_SUCCESS]: (state, action) => {
            return freeze({
                ...state,
                isLoading: false,
                listPatient: action.payload.map((item) => ({
                    label: `${item?.lastname} ${item?.firstname} - Mã BN: ${item?.id} ${
                        item?.dob ? `- ${moment(item?.dob).format("DD/MM/YYYY")}` : ""
                    }`,
                    value: item?.id,
                })),
            });
        },
        [actions.GET_ALL_MEDICALS_SUCCESS]: (state, action) => {
            return freeze({
                ...state,
                medicals: action.payload.map((item) => ({ label: `${item?.name}`, value: item?.id })),
            });
        },

        [actions.CHANGE_INFO_ORDER]: (state, action) => {
            return freeze({
                ...state,
                isLoading: true,
            });
        },
        [actions.CHANGE_INFO_ORDER_SUCCESS]: (state, action) => {
            return freeze({
                ...state,
                isLoading: false,
                order: {
                    ...state.order,
                    ...action.payload,
                    createdat: moment(action.payload?.createdat).format("YYYY-MM-DD"),
                    paymentDate: moment(action.payload?.paymentDate).format("YYYY-MM-DD"),
                    updatedat: moment(action.payload?.updatedat).format("DD/MM/YYYY"),
                    updatedby: `${action.payload?.updatedby?.lastname} ${action.payload?.updatedby?.firstname}`,
                    date: moment(action.payload?.date).format("YYYY-MM-DD"),
                },
            });
        },
        [actions.CHANGE_INFO_ORDER_FAILED]: (state, action) => {
            return freeze({
                ...state,
                isLoading: false,
            });
        },
        [actions.CLEAR]: (state, action) => {
            return freeze(initialState);
        },

        //refactor
        [actions.HANDLE_ITEMS_LINE]: (state, action) => {
            const { row, item, isOverride } = action.payload;
            const resItem = calcDataItem({
                isGrossProfit: row.isGrossProfit,
                perGrossProfit: row.perGrossProfit,
                quantity: row.quantity !== 0 ? row.quantity : 1,
                price: isOverride ? item.price : row.price,
                perTax: isOverride ? item.taxPrices : row.perTax,
                basePrice: item.basePrice ? item.basePrice : row.basePrice,
                available: item.available ? item.available : row.available,
            });
            return freeze({
                ...state,
                orderItems: state.orderItems.map((oldItem) => {
                    if (!row.id) if (oldItem.index === row.index) return { ...oldItem, ...row, ...resItem, item };
                    if (oldItem.id === row.id) return { ...oldItem, ...row, ...resItem, item };
                    return oldItem;
                }),
            });
        },
        [actions.HANDLE_SEVICES_LINE]: (state, action) => {
            const resServices = calcDataServices({ price: action.payload.price, perTax: action.payload.perTax });
            return freeze({
                ...state,
                orderServices: state.orderServices.map((oldServices) => {
                    if (!action.payload.id)
                        if (oldServices.index === action.payload.index)
                            return { ...oldServices, ...action.payload, ...resServices };
                    if (oldServices.id === action.payload.id)
                        return { ...oldServices, ...action.payload, ...resServices };
                    return oldServices;
                }),
            });
        },
        [actions.GET_INFO_ORDERS_SUCCESS]: (state, action) => {
            return freeze({
                ...state,
                isLoading: false,
                order: {
                    ...action.payload,
                    createdat: moment(action.payload?.createdat).format("YYYY-MM-DD"),
                    paymentDate: moment(action.payload?.paymentDate).format("YYYY-MM-DD"),
                    updatedat: moment(action.payload?.updatedat).format("DD/MM/YYYY"),
                    updatedby: `${action.payload?.updatedby?.lastname} ${action.payload?.updatedby?.firstname}`,
                    date: moment(action.payload?.date).format("YYYY-MM-DD"),
                },
                orderItems:
                    action.payload.status === "paid"
                        ? action.payload.items
                        : [...action.payload.items, DEFAULT_VALUE_ITEM],
                orderServices:
                    action.payload.status === "paid"
                        ? action.payload.expenses
                        : [...action.payload.expenses, DEFAULT_VALUE_SERVICE],
            });
        },
    },
    initialState
);
