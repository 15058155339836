/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import * as actions from "./actions";
import freeze from "deep-freeze";
import { handleActions } from "redux-actions";
import moment from "moment";

export const initialState = freeze({
  templates: [],
  order: {},
  isLoading: false,
  items: [],
});

export const name = "TEMPLATES";

export default handleActions(
  {
    [actions.GET_ALL_TEMPLATES]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true,
      });
    },
    [actions.GET_ALL_TEMPLATES_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        templates: action.payload.find_all_medical_form?.data.map((item) => {
          return {
            ...item,
            startDate: item?.startDate
              ? moment(item?.startDate).format("DD/MM/YYYY")
              : "",
            endDate: item?.endDate
              ? moment(item?.endDate).format("DD/MM/YYYY")
              : "",
          };
        }),
        page: action.payload.find_all_medical_form?.skip,
        pageSize: action.payload.find_all_medical_form?.take,
        totalPage: Math.ceil(
          action.payload.find_all_medical_form?.total /
            action.payload.find_all_medical_form?.take
        ),
        isNextPage: action.payload.find_all_medical_form?.nextPage,
      });
    },
    [actions.GET_ALL_TEMPLATES_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },
    [actions.GET_ALL_FAST_ITEM_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        items: action.payload.find_fast_item?.map((item) => {
          return {
            value: item.id,
            label: item.name,
            unit: item?.unit,
            price: parseInt(item?.salePrices),
            bin: parseInt(item?.salePrices),
            taxPrices: parseInt(item?.taxPrices),
          };
        }),
      });
    },
    [actions.GET_ALL_PATIENT_INFO_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        listPatient: action.payload?.find_fast_patient?.map((item) => {
          return {
            label: `${item?.lastname} ${item?.firstname} - Mã BN: ${item?.id} ${
              item?.dob ? `- ${moment(item?.dob).format("DD/MM/YYYY")}` : ""
            }`,
            value: item?.id,
          };
        }),
      });
    },
    [actions.GET_ALL_MEDICALS_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        medicals: action.payload?.find_all_medical?.data?.map((item) => {
          return {
            label: `${item?.name}`,
            value: item?.id,
          };
        }),
      });
    },
    [actions.GET_INFO_TEMPLATE]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true,
      });
    },
    [actions.GET_INFO_TEMPLATE_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        order: {
          ...action.payload,
          createdat: moment(action.payload?.createdat).format("YYYY-MM-DD"),
          paymentDate: moment(action.payload?.paymentDate).format("YYYY-MM-DD"),
          updatedat: moment(action.payload?.updatedat).format("DD/MM/YYYY"),
          updatedby: `${action.payload?.updatedby?.lastname} ${action.payload?.updatedby?.firstname}`,
          date: moment(action.payload?.date).format("YYYY-MM-DD"),
        },
      });
    },
    [actions.GET_INFO_TEMPLATE_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },
    [actions.CHANGE_INFO_TEMPLATE]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true,
      });
    },
    [actions.CHANGE_INFO_TEMPLATE_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },
    [actions.CHANGE_INFO_TEMPLATE_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },
    [actions.CLEAR]: (state, action) => {
      return freeze(initialState);
    },
  },
  initialState
);
