/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import * as actions from "./actions";
import freeze from "deep-freeze";
import { handleActions } from "redux-actions";
import moment from "moment";

export const initialState = freeze({
  patient: {},
  patients: [],
  isLoading: false,
  pageSize: 10,
  totalPage: 1,
  isNextPage: null,
});

export const name = "PATIENTS";

export default handleActions(
  {
    [actions.GET_PATIENT_INFO]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true,
      });
    },
    [actions.GET_PATIENT_INFO_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        patient: {
          ...action.payload,
          phone:
            action.payload?.phone?.replace(" ", "")?.replace("(+84)", "") || "",
          dob: moment(action.payload?.dob).format("YYYY-MM-DD"),
          identityDate: moment(action.payload?.identityDate).format(
            "YYYY-MM-DD"
          ),
        },
      });
    },
    [actions.GET_PATIENT_INFO_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },
    [actions.GET_ALL_PATIENT_INFO]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true,
      });
    },
    [actions.GET_ALL_PATIENT_INFO_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        patients: action.payload.find_all_patient?.data.map((item) => {
          return {
            ...item,
            dob: moment(item.dob).format("DD/MM/YYYY"),
          };
        }),
        page: action.payload.find_all_patient?.skip,
        pageSize: action.payload.find_all_patient?.take,
        totalPage: Math.ceil(
          action.payload.find_all_patient?.total /
            action.payload.find_all_patient?.take
        ),
        isNextPage: action.payload.find_all_patient?.nextPage,
      });
    },
    [actions.GET_ALL_PATIENT_INFO_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },
    [actions.CLEAR]: (state, action) => {
      return freeze(initialState);
    },
  },
  initialState
);
