export const DEFAULT_VALUE_ITEM = {
    id: null,
    index: 0,
    name: "",
    unit: "",
    note: "",
    isGrossProfit: false,
    perGrossProfit: 0,
    quantity: 1,
    price: 0,
    amount: 0,
    bin: 0,
    item: {
        id: "",
        name: "",
    },
    available: 0,
};
export const DEFAULT_VALUE_SERVICE = {
    id: null,
    index: 0,
    name: "",
    note: "",
    quantity: 0,
    price: 0,
    perTax: 0,
    amount: 0,
};

export const calcDataItem = ({ isGrossProfit, basePrice, perGrossProfit, price, quantity, perTax, available }) => {
    const newPrice = isGrossProfit ? (basePrice * (100 + perGrossProfit)) / 100 : price;
    const total = newPrice * quantity;
    const taxAmount = (total * perTax) / 100;
    const amount = total + taxAmount;
    return {
        isGrossProfit,
        basePrice,
        perGrossProfit,
        perTax,
        quantity,
        taxAmount,
        price: newPrice,
        amount,
        available,
    };
};

export const calcDataServices = ({ price, perTax }) => ({ amount: (1 + perTax / 100) * price, price, perTax });
