/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import * as actions from "./actions";
import freeze from "deep-freeze";
import { handleActions } from "redux-actions";
import moment from "moment";
import _ from "lodash";

export const initialState = freeze({
  scheduleList: [],
  schedule: {},
  isLoading: false,
  listPatient: [],
  newPatient: {},
  scheduleModeList: [],
});

export const name = "SCHEDULES";

export default handleActions(
  {
    [actions.GET_ALL_SCHEDULES]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true,
      });
    },
    [actions.GET_ALL_SCHEDULES_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        scheduleList: action.payload?.group_medical_scheduled?.map((item) => {
          return {
            ...item,
            data: [...item.data],
          };
        }),
      });
    },
    [actions.GET_ALL_SCHEDULES_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },
    [actions.GET_ALL_SCHEDULES_LIST]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true,
      });
    },
    [actions.GET_ALL_SCHEDULES_LIST_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        scheduleModeList: action.payload?.find_all_medical_scheduled.data?.map(
          (item) => ({ ...item, date: moment(item?.date).format("DD/MM/YYYY") })
        ),
        page: action.payload?.find_all_medical_scheduled?.skip,
        pageSize: action.payload?.find_all_medical_scheduled?.take,
        totalPage: Math.ceil(
          action.payload?.find_all_medical_scheduled?.total /
            action.payload?.find_all_medical_scheduled?.take
        ),
        isNextPage: action.payload?.find_all_medical_scheduled?.nextPage,
      });
    },
    [actions.GET_ALL_SCHEDULES_LIST_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },
    [actions.GET_INFO_SCHEDULE]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true,
      });
    },
    [actions.GET_INFO_SCHEDULE_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        schedule: {
          ...action.payload,
          date: moment(action.payload?.date).format("YYYY-MM-DD"),
          createdat: moment(action.payload?.createdat).format("DD/MM/YYYY"),
          updatedby: `${action.payload?.updatedby?.lastname} ${action.payload?.updatedby?.firstname}`,
        },
      });
    },
    [actions.GET_INFO_SCHEDULE_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },

    [actions.GET_ALL_PATIENT_INFO]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true,
      });
    },
    [actions.GET_ALL_PATIENT_INFO_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        listPatient: action.payload?.find_fast_patient?.map((item) => {
          return {
            label: `${item?.lastname} ${item?.firstname} - Mã BN: ${item?.id} ${
              item?.dob ? `- ${moment(item?.dob).format("DD/MM/YYYY")}` : ""
            }`,
            value: item?.id,
          };
        }),
      });
    },
    [actions.GET_ALL_PATIENT_INFO_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },
    [actions.ADD_PATIENT_INFO]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true,
      });
    },
    [actions.ADD_PATIENT_INFO_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        listPatient: [
          ...state.listPatient,
          {
            label: `${action.payload.save_patient?.lastname} ${
              action.payload.save_patient?.firstname
            } - Mã BN: ${action.payload.save_patient?.id} ${
              action.payload.save_patient?.dob
                ? `- ${moment(action.payload.save_patient?.dob).format(
                    "DD/MM/YYYY"
                  )}`
                : ""
            }`,
            value: action.payload.save_patient?.id,
          },
        ],
        newPatient: {
          label: `${action.payload.save_patient?.lastname} ${
            action.payload.save_patient?.firstname
          } - Mã BN: ${action.payload.save_patient?.id} ${
            action.payload.save_patient?.dob
              ? `- ${moment(action.payload.save_patient?.dob).format(
                  "DD/MM/YYYY"
                )}`
              : ""
          }`,
          value: action.payload.save_patient?.id,
        },
      });
    },
    [actions.ADD_PATIENT_INFO_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },
    [actions.CLEAR]: (state, action) => {
      return freeze(initialState);
    },
  },
  initialState
);
