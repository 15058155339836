/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Manager User
 */
import { lazy } from "react";

export const ROUTER_MANAGE_ITEMS = "/app/dashboard/items";

const routers = [
    {
        exact: true,
        path: ROUTER_MANAGE_ITEMS,
        component: lazy(() => import("./views/ItemList")),
    },
    {
        path: `${ROUTER_MANAGE_ITEMS}/:id`,
        component: lazy(() => import("./views/ItemInfo")),
    }
];
export default routers;
