/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import { createAction } from "redux-actions";

export const GET_USER_INFO = createAction("AUTHENTICATION_GET_USER_INFO");
export const GET_USER_INFO_SUCCESS = createAction("AUTHENTICATION_GET_USER_INFO_SUCCESS");
export const GET_USER_INFO_FAILED = createAction("AUTHENTICATION_GET_USER_INFO_FAILED");
