/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Manager User
 */
import { lazy } from "react";

export const ROUTER_INTEGRATE_EMPLOYEE = "/app/integrate/employee";
export const ROUTER_INTEGRATE_ITEM = "/app/integrate/item";
export const ROUTER_INTEGRATE_PATIENT = "/app/integrate/patient";

const routers = [
    {
        exact: true,
        path: ROUTER_INTEGRATE_EMPLOYEE,
        component: lazy(() => import("./employees/customtable")),
    },
    {
        exact: true,
        path: ROUTER_INTEGRATE_ITEM,
        component: lazy(() => import("./items/customtable")),
    },
    {
        exact: true,
        path: ROUTER_INTEGRATE_PATIENT,
        component: lazy(() => import("./patients/customtable")),
    },
];
export default routers;
