/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import main from "./main";
import { fork, all } from "redux-saga/effects";

export default function* rootSaga() {
    yield all(main.map((saga) => fork(saga)));
}
