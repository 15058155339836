import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import cookie from "js-cookie";
import jwtDecode from "jwt-decode";

import * as actions from "../../screens/Authentication/actions";
import { APIS_FIND_ONE_USER_INFOR } from "../../screens/Authentication/apis";

const verifyToken = (serviceToken) => {
    if (!!serviceToken === false) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    return decoded.exp > Date.now() / 1000;
};

const AuthGuard = ({ children }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { isLoggedIn } = useSelector((state) => state["AUTHENTICATION"]);
    const queryUser = useQuery("find_one_user_infor_auth", APIS_FIND_ONE_USER_INFOR, { enabled: !isLoggedIn });

    useEffect(() => {
        const accesstoken = cookie.get("accesstoken");
        if (!!accesstoken === false || !verifyToken(accesstoken)) {
            cookie.remove("accesstoken");
            dispatch(actions.LOGOUT);
            history.push("/auth/signin");
        }

        if (!queryUser.isLoading) {
            if (!queryUser.isError) {
                const { decentralization } = queryUser.data.find_one_user_info;
                dispatch(actions.LOGIN_SUCCESS(queryUser.data.find_one_user_info));
                if (!decentralization || decentralization.length === 0) history.push("/profile");
                else {
                    let isProfile = true;

                    Object.keys(decentralization[0]).forEach((key) => {
                        if (decentralization[0][key] > 0) {
                            isProfile = false;
                        }
                    });
                    if (isProfile) history.push("/profile");
                }
            } else {
                dispatch(actions.LOGIN_FAILED(queryUser.error));
                history.push("/auth/signin");
            }
        }
    }, [queryUser.isLoading]);

    return <React.Fragment>{children}</React.Fragment>;
};

export default AuthGuard;
