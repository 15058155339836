/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import { createAction } from "redux-actions";

export const GET_PATIENT_INFO = createAction("PATIENT/GET_PATIENT_INFO");
export const GET_PATIENT_INFO_SUCCESS = createAction(
  "PATIENT/GET_PATIENT_INFO_SUCCESS"
);
export const GET_PATIENT_INFO_FAILED = createAction(
  "PATIENT/GET_PATIENT_INFO_FAILED"
);

export const GET_ALL_PATIENT_INFO = createAction(
  "PATIENT/GET_ALL_PATIENT_INFO"
);
export const GET_ALL_PATIENT_INFO_SUCCESS = createAction(
  "PATIENT/GET_ALL_PATIENT_INFO_SUCCESS"
);
export const GET_ALL_PATIENT_INFO_FAILED = createAction(
  "PATIENT/GET_ALL_PATIENT_INFO_FAILED"
);

export const CLEAR = createAction("PATIENT/CLEAR_PATIENT");

export const DELETE_EMPLOYEE = createAction("PATIENT/DELETE_EMPLOYEE");
export const DELETE_PATIENT_SUCCESS = createAction(
  "PATIENT/DELETE_PATIENT_SUCCESS"
);
export const DELETE_PATIENT_FAILED = createAction(
  "PATIENT/DELETE_PATIENT_FAILED"
);

export const ADD_PATIENT_INFO = createAction("PATIENT/ADD_PATIENT_INFO");
export const ADD_PATIENT_INFO_SUCCESS = createAction(
  "PATIENT/ADD_PATIENT_INFO_SUCCESS"
);
export const ADD_PATIENT_INFO_FAILED = createAction(
  "PATIENT/ADD_PATIENT_INFO_FAILED"
);
