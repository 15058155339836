/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import * as actions from "./actions";
import freeze from "deep-freeze";
import { handleActions } from "redux-actions";
import moment from "moment";

export const initialState = freeze({
  items: [],
  item: {},
  isLoading: false,
});

export const name = "ITEMS";

export default handleActions(
  {
    [actions.GET_ALL_ITEMS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true,
      });
    },
    [actions.GET_ALL_ITEMS_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        items: action.payload.find_all_item?.data.map((item) => {
          let then = moment().utc().format();
          let now = moment(item?.endDate).utc().format();

          let checkLeft = moment(now).diff(moment(then)) / 3600000 / 24;

          return {
            ...item,
            customBackground:
              checkLeft && checkLeft <= 0
                ? "#ff1f1f"
                : checkLeft <= 7
                ? "#ffff6e"
                : "transparent",
            customColor:
              checkLeft && checkLeft <= 0
                ? "white"
                : checkLeft <= 7
                ? "black"
                : "#9b919b",
            startDate: item?.startDate
              ? moment(item?.startDate).format("DD/MM/YYYY")
              : "",
            endDate: item?.endDate
              ? moment(item?.endDate).format("DD/MM/YYYY")
              : "",
          };
        }),
        page: action.payload.find_all_item?.skip,
        pageSize: action.payload.find_all_item?.take,
        totalPage: Math.ceil(
          action.payload.find_all_item?.total /
            action.payload.find_all_item?.take
        ),
        isNextPage: action.payload.find_all_item?.nextPage,
      });
    },
    [actions.GET_ALL_ITEMS_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },
    [actions.GET_INFO_ITEM]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true,
      });
    },
    [actions.GET_INFO_ITEM_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        item: {
          ...action.payload,
          startDate: moment(action.payload?.startDate).format("YYYY-MM-DD"),
          endDate: moment(action.payload?.endDate).format("YYYY-MM-DD"),
          createdat: moment(action.payload?.createdat).format("YYYY-MM-DD"),
          updatedat: moment(action.payload?.updatedat).format("DD/MM/YYYY"),
          updatedby: `${action.payload?.updatedby?.lastname} ${action.payload?.updatedby?.firstname}`,
          tax: "VAT",
          taxPrices: 10,
        },
      });
    },
    [actions.GET_INFO_ITEM_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },
    [actions.CHANGE_INFO_ITEM]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true,
      });
    },
    [actions.CHANGE_INFO_ITEM_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        item: {
          ...action.payload.save_item,
          startDate: moment(action.payload?.save_item.startDate).format(
            "YYYY-MM-DD"
          ),
          endDate: moment(action.payload?.save_item.endDate).format(
            "YYYY-MM-DD"
          ),
        },
      });
    },
    [actions.CHANGE_INFO_ITEM_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },
    [actions.CLEAR]: (state, action) => {
      return freeze(initialState);
    },
  },
  initialState
);
