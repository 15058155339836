/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Manager User
 */
import { lazy } from "react";

export const ROUTER_MANAGE_MEDICALS = "/app/dashboard/medicals";

const routers = [
  {
    exact: true,
    path: ROUTER_MANAGE_MEDICALS,
    component: lazy(() => import("./views/AllMedicalList")),
  },
  {
    path: `${ROUTER_MANAGE_MEDICALS}/:id`,
    component: lazy(() => import("./views/MedicalProfile")),
  },
];
export default routers;
