import React, { useContext, useEffect, useRef } from "react";

import Navigation from "./Navigation";
import NavBar from "./NavBar";
import Breadcrumb from "./Breadcrumb";

import useWindowSize from "../../hooks/useWindowSize";
import useOutsideClick from "../../hooks/useOutsideClick";
import { ConfigContext } from "../../contexts/ConfigContext";
import * as actionType from "../../store/actions";
import { useHistory } from "react-router-dom";

import { ROUTER_MANAGE_PATIENT } from "../../screens/ManagePatient/router";
import { ROUTER_MANAGE_ITEMS } from "../../screens/ManageItems/router";
import { ROUTER_MANAGE_USER } from "../../screens/ManageUser/router";
import { ROUTER_MANAGE_SCHEDULE } from "../../screens/ManageSchedulers/router";

import { CLEAR as CLEAR_USER } from "../../screens/ManageUser/actions";
import { CLEAR as CLEAR_ITEM } from "../../screens/ManageItems/actions";
import { CLEAR as CLEAR_PATIENT } from "../../screens/ManagePatient/actions";
import { CLEAR as CLEAR_SCHEDULES } from "../../screens/ManageSchedulers/actions";
import { useDispatch } from "react-redux";

const AdminLayout = ({ children }) => {
  const history = useHistory();
  const windowSize = useWindowSize();
  const dispatchAction = useDispatch();
  const ref = useRef();
  const configContext = useContext(ConfigContext);

  const { collapseMenu, layout, subLayout, headerFixedLayout, configBlock } =
    configContext.state;
  const { dispatch } = configContext;

  useEffect(() => {
    switch (history.location.pathname) {
      case ROUTER_MANAGE_USER:
        dispatchAction(CLEAR_SCHEDULES());
        dispatchAction(CLEAR_PATIENT());
        dispatchAction(CLEAR_ITEM());
        break;
      case ROUTER_MANAGE_PATIENT:
        dispatchAction(CLEAR_SCHEDULES());
        dispatchAction(CLEAR_USER());
        dispatchAction(CLEAR_ITEM());
        break;
      case ROUTER_MANAGE_SCHEDULE:
        dispatchAction(CLEAR_USER());
        dispatchAction(CLEAR_PATIENT());
        dispatchAction(CLEAR_ITEM());
        break;
      case ROUTER_MANAGE_ITEMS:
        dispatchAction(CLEAR_SCHEDULES());
        dispatchAction(CLEAR_PATIENT());
        dispatchAction(CLEAR_USER());
        break;
    }
  }, [history.location.pathname]);

  useOutsideClick(ref, () => {
    if (collapseMenu) {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }
  });

  useEffect(() => {
    if (
      windowSize.width > 992 &&
      windowSize.width <= 1024 &&
      layout !== "horizontal"
    ) {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }

    if (layout === "horizontal" && windowSize.width < 992) {
      dispatch({ type: actionType.CHANGE_LAYOUT, layout: "vertical" });
    }
  }, [dispatch, layout, windowSize]);

  const mobileOutClickHandler = () => {
    if (windowSize.width < 992 && collapseMenu) {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }
  };

  let mainClass = ["pcoded-wrapper"];
  if (layout === "horizontal" && subLayout === "horizontal-2") {
    mainClass = [...mainClass, "container"];
  }

  let common = (
    <React.Fragment>
      <Navigation />
    </React.Fragment>
  );

  let mainContainer = (
    <React.Fragment>
      <NavBar />
      <div className="pcoded-main-container">
        <div className={mainClass.join(" ")}>
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <Breadcrumb />
              {children}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  if (windowSize.width < 992) {
    let outSideClass = ["nav-outside"];
    if (collapseMenu) {
      outSideClass = [...outSideClass, "mob-backdrop"];
    }
    if (headerFixedLayout) {
      outSideClass = [...outSideClass, "mob-fixed"];
    }

    common = (
      <div className={outSideClass.join(" ")} ref={ref}>
        {common}
      </div>
    );

    mainContainer = (
      <div className="pcoded-outside" onClick={() => mobileOutClickHandler}>
        {mainContainer}
      </div>
    );
  }

  return (
    <React.Fragment>
      {common}
      {mainContainer}
    </React.Fragment>
  );
};

export default AdminLayout;
