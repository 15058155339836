/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Manager User
 */
import { lazy } from "react";

export const ROUTER_MANAGE_TEMPLATE = "/app/dashboard/templates";

const routers = [
  {
    exact: true,
    path: ROUTER_MANAGE_TEMPLATE,
    component: lazy(() => import("./views/TemplateList")),
  },
  {
    path: `${ROUTER_MANAGE_TEMPLATE}/:id`,
    component: lazy(() => import("./views/TemplateInfo")),
  },
];
export default routers;
