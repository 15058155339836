/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import { lazy } from "react";
export const ROUTER_PROFILE = "/profile";

const routers = [
    {
        exact: true,
        path: ROUTER_PROFILE,
        component: lazy(() => import("./views/Profile")),
    },
];
export default routers;
