/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import { createAction } from "redux-actions";

export const GET_ALL_ITEMS = createAction("ITEMS/GET_ALL_ITEMS");
export const GET_ALL_ITEMS_SUCCESS = createAction(
  "ITEMS/GET_ALL_ITEMS_SUCCESS"
);
export const GET_ALL_ITEMS_FAILED = createAction("ITEMS/GET_ALL_ITEMS_FAILED");

export const GET_INFO_ITEM = createAction("ITEMS/GET_INFO_ITEM");
export const GET_INFO_ITEM_SUCCESS = createAction(
  "ITEMS/GET_INFO_ITEM_SUCCESS"
);
export const GET_INFO_ITEM_FAILED = createAction("ITEMS/GET_INFO_ITEM_FAILED");

export const CHANGE_INFO_ITEM = createAction("ITEMS/CHANGE_INFO_ITEM");
export const CHANGE_INFO_ITEM_SUCCESS = createAction(
  "CHANGE_INFO_ITEM_SUCCESS"
);
export const CHANGE_INFO_ITEM_FAILED = createAction(
  "ITEMS/CHANGE_INFO_ITEM_FAILED"
);

export const CLEAR = createAction("ITEMS/CLEAR_ITEMS");
