/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Manager User
 */
import { lazy } from "react";

export const ROUTER_MANAGE_ORDERS = "/app/dashboard/orders";

const routers = [
  {
    exact: true,
    path: ROUTER_MANAGE_ORDERS,
    component: lazy(() => import("./views/OrderList")),
  },
  {
    path: `${ROUTER_MANAGE_ORDERS}/view/:id`,
    component: lazy(() => import("./views/OrderView")),
  },
  {
    path: `${ROUTER_MANAGE_ORDERS}/:id`,
    component: lazy(() => import("./views/OrderInfo")),
  },
];
export default routers;
