/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import { lazy } from "react";
import GuestGuard from "../../components/Auth/GuestGuard";

export const ROUTER_SIGN_IN = "/auth/signin";
export const ROUTER_SIGN_UP = "/auth/signup";
export const ROUTER_CHANGE_PASSWORD = "/auth/change-password";
export const ROUTER_PROFILE = "AUTHENTICATION_PROFILE";

const routers = [
    {
        exact: true,
        // guard: GuestGuard,
        path: ROUTER_SIGN_IN,
        component: lazy(() => import("./views/SignIn")),
    },
    {
        exact: true,
        path: ROUTER_SIGN_UP,
        component: lazy(() => import("./views/SignUp")),
    },
    {
        exact: true,
        path: ROUTER_CHANGE_PASSWORD,
        component: lazy(() => import("./views/ChangePassword")),
    },
];
export default routers;
