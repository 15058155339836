/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import { createAction } from "redux-actions";

export const GET_ALL_ORDERS = createAction("PURCHASE_ORDER/GET_ALL_ORDERS");
export const GET_ALL_ORDERS_SUCCESS = createAction("PURCHASE_ORDER/GET_ALL_ORDERS_SUCCESS");
export const GET_ALL_ORDERS_FAILED = createAction("PURCHASE_ORDER/GET_ALL_ORDERS_FAILED");

export const GET_INFO_ORDERS = createAction("PURCHASE_ORDER/GET_INFO_ORDERS");
export const GET_INFO_ORDERS_SUCCESS = createAction("PURCHASE_ORDER/GET_INFO_ORDERS_SUCCESS");
export const GET_INFO_ORDERS_FAILED = createAction("PURCHASE_ORDER/GET_INFO_ORDERS_FAILED");

export const GET_ALL_FAST_ITEM = createAction("PURCHASE_ORDER/GET_ALL_FAST_ITEM");
export const GET_ALL_FAST_ITEM_SUCCESS = createAction("PURCHASE_ORDER/GET_ALL_FAST_ITEM_SUCCESS");
export const GET_ALL_FAST_ITEM_FAILED = createAction("PURCHASE_ORDER/GET_ALL_FAST_ITEM_FAILED");
export const GET_ALL_PATIENT_INFO = createAction("PURCHASE_ORDER/GET_ALL_PATIENT_INFO");
export const GET_ALL_PATIENT_INFO_SUCCESS = createAction("PURCHASE_ORDER/GET_ALL_PATIENT_INFO_SUCCESS");
export const GET_ALL_PATIENT_INFO_FAILED = createAction("PURCHASE_ORDER/GET_ALL_PATIENT_INFO_FAILED");

export const GET_ALL_MEDICALS = createAction("PURCHASE_ORDER/GET_ALL_MEDICALS");
export const GET_ALL_MEDICALS_SUCCESS = createAction("PURCHASE_ORDER/GET_ALL_MEDICALS_SUCCESS");
export const GET_ALL_MEDICALS_FAILED = createAction("PURCHASE_ORDER/GET_ALL_MEDICALS_FAILED");

export const CHANGE_INFO_ORDER = createAction("PURCHASE_ORDER/CHANGE_INFO_ORDER");
export const CHANGE_INFO_ORDER_SUCCESS = createAction("PURCHASE_ORDER/CHANGE_INFO_ORDER_SUCCESS");
export const CHANGE_INFO_ORDER_FAILED = createAction("PURCHASE_ORDER/CHANGE_INFO_ORDER_FAILED");

export const CLEAR = createAction("PURCHASE_ORDER/CLEAR_ORDERS");

export const HANDLE_ITEMS_LINE = createAction("PURCHASE_ORDER/HANDLE_ITEMS_LINE");
export const HANDLE_SEVICES_LINE = createAction("PURCHASE_ORDER/HANDLE_SERVICES_LINE");
