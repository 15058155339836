/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import * as actions from "./actions";
import freeze from "deep-freeze";
import { handleActions } from "redux-actions";
import moment from "moment";

export const initialState = freeze({
  employee: {},
  employees: [],
  isLoading: false,
  pageSize: 0,
  pageSize: 10,
  totalPage: 1,
  isNextPage: null,
});

export const name = "USERS";

export default handleActions(
  {
    [actions.GET_EMPLOYEE_INFO]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true,
      });
    },
    [actions.GET_EMPLOYEE_INFO_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        employee: {
          ...action.payload,
          phone:
            action.payload?.phone?.replace(" ", "")?.replace("(+84)", "") || "",
          dob: moment(action.payload?.dob).format("YYYY-MM-DD"),
        },
      });
    },
    [actions.GET_EMPLOYEE_INFO_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },
    [actions.GET_ALL_EMPLOYEE_INFO]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true,
      });
    },
    [actions.GET_ALL_EMPLOYEE_INFO_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
        employees: action.payload.find_all_user?.data,
        page: action.payload.find_all_user?.skip,
        pageSize: action.payload.find_all_user?.take,
        totalPage: Math.ceil(
          action.payload.find_all_user?.total /
            action.payload.find_all_user?.take
        ),
        isNextPage: action.payload.find_all_user?.nextPage,
      });
    },
    [actions.GET_ALL_EMPLOYEE_INFO_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false,
      });
    },
    [actions.CLEAR]: (state, action) => {
      return freeze(initialState);
    },
  },
  initialState
);
