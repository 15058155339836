/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import * as actions from "./actions";
import freeze from "deep-freeze";
import { handleActions } from "redux-actions";

export const initialState = freeze({
    user: null,
    isLoading: false
});

export const name = "PROFILE";

export default handleActions(
    {
        [actions.GET_USER_INFO]: (state, action) => {
            return freeze({
                ...state,
                isLoading: true
            });
        },
        [actions.GET_USER_INFO_SUCCESS]: (state, action) => {
            return freeze({
                ...state,
                isLoading: false,
                user: action.payload
            });
        },
        [actions.GET_USER_INFO_FAILED]: (state, action) => {
            return freeze({
                ...state,
                isLoading: false
            });
        },
    },
    initialState
);
