import { ROUTER_MANAGE_ITEMS } from "./screens/ManageItems/router";
import { ROUTER_MANAGE_ORDERS } from "./screens/ManageOrders/router";
import { ROUTER_MANAGE_PATIENT } from "./screens/ManagePatient/router";
import { ROUTER_MANAGE_USER } from "./screens/ManageUser/router";
import { ROUTER_MANAGE_SCHEDULE } from "./screens/ManageSchedulers/router";
import { ROUTER_DASHBOAR_REPORT_ADMIN } from "./screens/Dashboard/router";

import {
    ROUTER_INTEGRATE_EMPLOYEE,
    ROUTER_INTEGRATE_ITEM,
    ROUTER_INTEGRATE_PATIENT,
} from "./screens/Integration/router";
import { ROUTER_MANAGE_MEDICALS } from "./screens/ManageMedical/router";
import { ROUTER_MANAGE_PURCHASE } from "./screens/ManagePurchases/router";

const menuItems = {
    items: [
        {
            id: "navigation",
            title: "",
            type: "group",
            icon: "feather icon-monitor",
            children: [
                {
                    id: "system",
                    title: "Quản lý hệ thống",
                    type: "collapse",
                    icon: "feather icon-home",
                    permission: "employees",
                    children: [
                        {
                            id: "reports",
                            title: "Báo cáo",
                            type: "item",
                            icon: "feather icon-home",
                            url: ROUTER_DASHBOAR_REPORT_ADMIN,
                            permission: "reports",
                            external: true,
                        },
                        {
                            id: "user",
                            title: "Danh sách nhân viên",
                            type: "item",
                            icon: "feather icon-monitor",
                            url: ROUTER_MANAGE_USER,
                            permission: "employees",
                            external: true,
                        },
                    ],
                },
                {
                    id: "system_patient",
                    title: "Quản lý bệnh nhân",
                    type: "collapse",
                    icon: "feather icon-monitor",
                    permission: "employees",
                    children: [
                        {
                            id: "scheduled",
                            title: "Lịch khám bệnh",
                            type: "item",
                            icon: "feather icon-tv",
                            url: ROUTER_MANAGE_SCHEDULE,
                            permission: "schedules",
                            external: true,
                        },
                        {
                            id: "patient",
                            title: "Danh sách bệnh nhân",
                            type: "item",
                            icon: "feather icon-users",
                            url: ROUTER_MANAGE_PATIENT,
                            permission: "patients",
                            external: true,
                        },

                        {
                            id: "medical",
                            title: "Danh sách bệnh án",
                            type: "item",
                            icon: "feather icon-layers",
                            url: ROUTER_MANAGE_MEDICALS,
                            permission: "medical",
                            external: true,
                        },
                    ],
                },
                {
                    id: "system_pos",
                    title: "Quản lý nhà thuốc",
                    type: "collapse",
                    icon: "feather icon-monitor",
                    permission: "items",
                    children: [
                        {
                            id: "item",
                            title: "Danh sách sản phẩm",
                            type: "item",
                            icon: "feather icon-layers",
                            url: ROUTER_MANAGE_ITEMS,
                            permission: "items",
                            external: true,
                        },
                        {
                            id: "orders",
                            title: "Quản lý phiếu bán hàng",
                            type: "item",
                            icon: "feather icon-package",
                            url: ROUTER_MANAGE_ORDERS,
                            permission: "salesOrders",
                            external: true,
                        },
                        {
                            id: "purchases",
                            title: "Quản lý phiếu mua hàng",
                            type: "item",
                            icon: "feather icon-package",
                            url: ROUTER_MANAGE_PURCHASE,
                            permission: "purchaseOrders",
                            external: true,
                        },
                    ],
                },
                {
                    id: "integrate",
                    title: "Nhập dữ liệu",
                    type: "collapse",
                    icon: "feather icon-upload",
                    permission: "integration",
                    children: [
                        {
                            id: "integrate_user",
                            title: "Nhập nhân viên",
                            type: "item",
                            icon: "feather icon-monitor",
                            url: ROUTER_INTEGRATE_EMPLOYEE,
                            permission: "employees",
                        },
                        {
                            id: "integrate_item",
                            title: "Nhập sản phẩm",
                            type: "item",
                            icon: "feather icon-layers",
                            url: ROUTER_INTEGRATE_ITEM,
                            permission: "items",
                        },
                        {
                            id: "integrate_patient",
                            title: "Nhập bệnh nhân",
                            type: "item",
                            icon: "feather icon-users",
                            url: ROUTER_INTEGRATE_PATIENT,
                            permission: "patients",
                        },
                    ],
                },
            ],
        },
    ],
};

export default menuItems;
