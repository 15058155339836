/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import { createAction } from "redux-actions";

export const FIND_ALL_DATA_SUCCESS = createAction("INTEGRATION/FIND_ALL_DATA_SUCCESS");
export const FIND_ALL_DATA_FAILED = createAction("INTEGRATION/FIND_ALL_DATA_FAILED");

// integration table
export const HANDLE_DROP_BTN_TABLE = createAction("INTEGRATION/HANDLE_DROP_BTN_TABLE");
export const HANDLE_PAGINATION_TABLE = createAction("INTEGRATION/HANDLE_PAGINATION_TABLE");

export const SELECT_ROW = createAction("INTEGRATION/SELECT_ROW");
export const SELECT_ROW_SUCCESS = createAction("INTEGRATION/SELECT_ROW_SUCCESS");

export const SELECT_ONE = createAction("INTEGRATION/SELECT_ONE");
export const SELECT_ONE_SUCCESS = createAction("INTEGRATION/SELECT_ONE_SUCCESS");

//preview table
export const DELETE_ROW_TABLE_PREVIEW = createAction("INTEGRATION/DELETE_ROW_TABLE_PREVIEW");
export const HANDLE_DROP_BTN_TABLE_PREVIEW = createAction("INTEGRATION/HANDLE_DROP_BTN_TABLE_PREVIEW");

export const SELECT_ONE_TABLE_REVIEW = createAction("INTEGRATION/SELECT_ONE_TABLE_REVIEW");
export const SELECT_ONE_TABLE_REVIEW_SUCCESS = createAction("INTEGRATION/SELECT_ONE_TABLE_REVIEW_SUCCESS");
export const SELECT_ROW_TABLE_REVIEW = createAction("INTEGRATION/SELECT_ROW_TABLE_REVIEW");
export const SELECT_ROW_TABLE_REVIEW_SUCCESS = createAction("INTEGRATION/SELECT_ROW_TABLE_REVIEW_SUCCESS");
export const HANDLE_INPUT_TABLE_REVIEW = createAction("INTEGRATION/HANDLE_INPUT_TABLE_REVIEW");

//other
export const CLEAR = createAction("INTEGRATION/CLEAR");
export const IMPORT_DATA = createAction("IMPORT_DATA");
