/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import { call, take, fork } from "redux-saga/effects";

export function* takeAction(pattern, saga, ...args) {
    const task = yield fork(function* () {
        while (true) {
            const action = yield take(pattern);
            yield call(saga, ...args.concat(action));
        }
    });
    return task;
}
