export const roleOption = {
  admin: "Quản trị viên",
  employee: "Nhân viên",
};

export const genderOption = {
  male: "Nam",
  female: "Nữ",
};

export const buttOption = {
  verify: "verify",
  save: "save",
  delete: "delete",
};

export const handlePagination = ({ take, skip, total, ...pagination }) => ({
  take: take,
  skip: skip,
  totalPage: Math.floor(total / take) > 0 ? Math.floor(total / take) : 1,
  canPreviousPage: skip > 0,
  canNextPage: Math.floor(total / take) > skip + 1,
  nextPage: skip + 1,
  previousPage: skip - 1,
});

export const processData = async ({ dataString, convertFile }) => {
  const dataStringLines = dataString.split(/\r\n|\n/);
  const headers = dataStringLines[0].split(
    /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
  );

  const list = [];
  for (let i = 1; i < dataStringLines.length; i++) {
    const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
    if (headers && row.length === headers.length) {
      const obj = {};
      for (let j = 0; j < headers.length; j++) {
        let d = row[j];
        if (d.length > 0) {
          if (d[0] === '"') d = d?.substring(1, d.length - 1);
          if (d[d.length - 1] === '"') d = d?.substring(d.length - 2, 1);
        }
        if (convertFile[headers[j]]) {
          obj[convertFile[headers[j]].value] = d;
        }
      }

      // remove the blank rows
      if (Object.values(obj).filter((x) => x).length > 0) {
        list.push(obj);
      }
    }
  }

  return {
    rows: list.map((item, index) => ({
      ...item,
      index: index,
      button: buttOption.save,
    })),
    columns: headers.map((c) => ({
      name: c,
      selector: c,
    })),
  };
};
