import { GraphQLClient } from "graphql-request";
import { gql } from "graphql-request";

import cookie from "js-cookie";
import { CONFIG } from "../config/constant";

const configGraphQl = async ({ queryString }) => {
    try {
        const graphQLClient = new GraphQLClient(CONFIG.endpoint, {
            headers: {
                Authorization: `Bearer ${(await cookie.get("accesstoken")) || ""}`,
            },
        });
        const res = await graphQLClient.request(
            gql`
                ${queryString}
            `
        );
        return res;
    } catch (error) {
        if (error.message.includes("jwt must be provided")) throw new Error(`Accesstoken expaired`);
        throw error;
    }
};
export default configGraphQl;
