/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */
import graphQLClient from "../../services/graphql";

export const APIS_LOGIN = async ({ input, output }) =>
    await graphQLClient({
        queryString: `
        mutation {
    login_user(input: { username: "${input.username}", password: "${input.password}" }) {
        id
        firstname
        lastname
        email
        phone
        gender
        address
        city
        province
        nation
        cardID
        note
        status
        dob
        role
        username
        question
        answer
        accesstoken
        decentralization {
            employees
            patients
            medical
            schedules
            items
            salesOrders
            purchaseOrders
            adjustments
            reports
          }
    }
}`,
    });

export const APIS_CHANGE_PASS = async ({ input }) =>
    await graphQLClient({
        queryString: `
        mutation {
            change_password(input: { oldPassword: "${input.oldPassword}", newPassword: "${input.newPassword}" }) {
                id,
                firstname,
                lastname
            }
    }`,
    });

export const APIS_FIND_ONE_USER_INFOR = async () =>
    await graphQLClient({
        queryString: `
        query {
        find_one_user_info {
            id
            firstname
            lastname
            email
            phone
            gender
            address
            city
            province
            nation
            cardID
            note
            status
            dob
            role
            username
            question
            answer 
            decentralization {
                employees
                patients
                medical
                schedules
                items
                salesOrders
                purchaseOrders
                adjustments
                reports
              }
        }
    }`,
    });
