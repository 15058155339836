/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (reacr query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanhtho        master          1.0.0              Initital project
 */

import * as actions from "./actions";
import sagas from "./sagas";
import reducer, { name } from "./reducer";
export { name, actions, sagas };

export default reducer;
